html {
  --section-background-color: linear-gradient(to bottom left, #fcfafc, #fcfafc);

  --imp-text-color: #000000;
}

/* --------- */
/*  Preloader */
/* --------- */
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: white;
  background-image: url(./Assets/sup/pre.svg);
  background-repeat: no-repeat;
  background-position: center;
}

#preloader-none {
  opacity: 0;
}

#no-scroll {
  overflow: hidden;
  height: 100vh;
}

/* --------- */
/*Scrollbar   */
/* --------- */

::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #001412;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #000000;
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #000000;
  border-radius: 12px;
}

/* --------- */
/* Navbar Section  */
/* --------- */
.sticky {
  opacity: 0.7;
  backdrop-filter: blur(15px) !important;
  background-color: #0024f1;
}

.logo {
  height: 8em !important;
  width: 10em !important;
}

.navbar {
  position: fixed !important;
  transition: all 0.3s ease-out 0s !important;
  padding: 0.3rem 2rem !important;
  font-size: 1.2rem !important;
}

.navbar-toggler {
  padding: 0.25rem 0rem !important;
  position: relative !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.navbar-toggler span {
  display: block !important;
  background-color: #000000 !important;
  height: 4px !important;
  width: 27px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  transform: rotate(0deg) !important;
  left: 0 !important;
  opacity: 1 !important;
}

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: 0 !important;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  transition: transform 0.35s ease-in-out !important;
  transition: transform 0.35s ease-in-out !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(135deg) !important;
  opacity: 0.9 !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px !important;
  visibility: hidden !important;
  background-color: transparent !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(-135deg) !important;
  opacity: 0.9 !important;
}

.navbar-social {
}

.navbar-social img {
  width: 1.7rem;
  border-radius: 50%;
}

.navbar-social span {
  font-size: 1rem;
  font-weight: 900;
  color: #ff00ff;
}

.navbar-brand {
  color: rgb(250, 250, 250) !important;
}

.navbar-nav {
  width: 100%;
}

.navbar-nav .row {
  margin-right: 0;
  margin-left: 0;
}

.navbar-nav .nav-link {
  color: white !important;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.nav-link {
  padding: 0.8rem 1rem !important;
}

.navbar-nav .nav-item {
  position: relative;
  display: table-cell;
}

.navbar-nav .nav-item a {
  font-size: 1rem;
  transition: all 0.3s ease-out 0s;
  position: relative;
  z-index: 1;
}

.navbar-nav .nav-item a::after {
  content: '';
  position: relative;
  display: block;
  height: 5px;
  width: 0;
  border-radius: 16px;
  background: #000000;
  bottom: 1px;
  left: 0;
  z-index: -1;
  transition: all 0.3s ease-out 0s;
}

.navbar-nav .nav-item a:hover::after {
  width: 100%;
}

@media (max-width: 980px) {
  .nav-link {
    padding: 0.7rem 1rem !important;
    background-color: #000000;
  }
  .logo {
    height: 4em !important;
    width: 4.5em !important;
  }
  .navbar {
    padding: 1rem 2rem !important;
    font-size: 1.4rem !important;
    background-color: #00000000 !important;
    opacity: 0.95;
  }
  .navbar-nav .nav-item a::after {
    display: none !important;
  }
}

/* --------- */
/* Home */
/* --------- */
.home-header {
  padding-top: 80px !important;
}

.home-content {
  max-width: 70% !important;
  padding: 15rem 0 0 !important;
  color: whitesmoke;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
}

.home-content-top {
  width: 100%;
}

.home-text {
  background-color: #000000;
  color: white;
  font-size: 45px;
  font-weight: 900;
}
.home-text2 {
  background-color: #000000b6;
  color: red;
  font-size: 43px;
}
.home-logo {
  width: 10rem;
}

.home-social {
  width: 55%;
  margin: 0 auto;
  display: flex;
}

.home-social-top {
  display: flex;
}

.home-social-bot {
  display: flex;
}

.home-socials {
  width: auto;
  border-radius: 50%;
  transition: 0.5s;
  transform: scale(0.9);
  padding-right: 0.2rem;
}

.home-socials a {
  width: auto;
}

.home-socials img {
  width: 5.5rem;
  border-radius: 50%;
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.social-icons {
  display: inline-block !important;
  padding-right: 15px;
  padding-left: 15px;
}

.social-icons:hover {
  color: #000000;
}

.icon-colour {
  color: #000000 !important;
}

@media (max-width: 980px) {
  .home-content {
    max-width: 100% !important;
    padding: 9rem 0 0 !important;
  }
  .home-text {
    font-size: 1.3rem;
  }
  .home-text2 {
    font-size: 0.9rem;
  }
  .home-text3 {
    font-size: 1rem;
  }
  .home-text4 {
    font-size: 0.7rem;
  }

  .home-social {
    width: 100%;
    display: block;
  }
  .home-social-top {
    display: inline-flex;
  }
  .home-social-bot {
    display: inline-flex;
  }

  .home-socials img {
    width: 4rem;
  }
  .home-header {
    padding-top: 1px !important;
  }
}

/* --------- */
/* about */
/* --------- */
.about-section {
  position: relative;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 7rem !important;
}

.about-content {
  width: 70%;
  margin: 0 auto;
}

.about-content .row {
  margin-bottom: 4rem;
}

.about-logo {
  border-radius: 50%;
  animation: app-logo-spin infinite 5s linear;
}

@keyframes app-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.about-top {
  background-image: url('Assets/main/bg-about.webp');
  border-radius: 2rem;
  padding: 2rem;
  cursor: pointer;
  z-index: 999;
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.1s;
  -webkit-transition-timing-function: ease;
}
.about-top:hover {
  transform: scale(1.1);
}

.about-mid {
  background-image: url('Assets/main/bg-about2.webp');
  border-radius: 2rem;
  padding: 2rem;
  cursor: pointer;
  z-index: 999;
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.1s;
  -webkit-transition-timing-function: ease;
}
.about-mid:hover {
  transform: scale(1.2);
}

.about-bot {
  background-image: url('Assets/main/bg-about3.webp');
  border-radius: 2rem;
  padding: 2rem;
  cursor: pointer;
  z-index: 999;
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.1s;
  -webkit-transition-timing-function: ease;
}
.about-bot:hover {
  transform: scale(1.2);
}

.about-text {
  color: rgb(255, 255, 255);
  font-size: 31px;
  font-weight: 700;
}

.about-text2 {
  color: rgb(255, 255, 255);
  font-size: 25px;
  font-weight: 700;
}

.about-text3 {
  color: rgb(255, 255, 255);
  font-size: 30px;
  font-weight: 700;
}

@media (max-width: 980px) {
  .about-section {
    padding: 1rem !important;
  }
  .about-content {
    width: 100%;
  }
  .about-text {
    font-size: 1rem;
  }
  .about-logo {
    width: 15rem;
  }
}

#dexscreener-embed {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
}
@media (min-width: 1400px) {
  #dexscreener-embed {
    padding-bottom: 35%;
  }
}
#dexscreener-embed iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 0;
}

/* --------- */
/* roadmap */
/* --------- */
.roadmap-section {
  position: relative !important;
  padding-top: 110px !important;
  padding-bottom: 30px !important;
}

.roadmap {
  background-image: url('Assets/main/bg-roadmap.webp');
  border-radius: 2rem;
  padding: 3rem;
  cursor: pointer;
  z-index: 999;
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.1s;
  -webkit-transition-timing-function: ease;
  margin-left: 0 !important;
}

.roadmap-left {
  padding-right: 80px !important;
}

.roadmap-right {
  padding-left: 80px !important;
}

.roadmap-text {
}

.roadmap .roadmap-title {
  color: white;
  font-size: 2.5em;
  font-weight: 700;
  padding-top: 30px;
  padding-bottom: 30px;
}

.roadmap .roadmap-item {
  padding: 0 0 10px 25px;
  margin-top: -2px;
  border-left: 2px solid #c4b385;
  position: relative;
}

.roadmap .roadmap-item .roadmap-title {
  line-height: 16px;
  font-size: 1.5em;
  color: white;
  padding: 8px 15px;
  display: inline-block;
  font-weight: 600;
  margin-bottom: 1px;
}

.roadmap .roadmap-item ul {
  padding-left: 20px;
  text-align: justify;
}

.roadmap .roadmap-item ul li {
  padding-bottom: 10px;
  list-style: none;
}

.roadmap .roadmap-item:last-child {
  padding-bottom: 0;
}

.roadmap .roadmap-item::before {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50px;
  left: -9px;
  top: 0;
  background: #fff;
  border: 2px solid #c4b385;
}

@media (max-width: 980px) {
  .roadmap-text {
    font-size: 4rem;
    font-weight: 700;
  }
  .roadmap .roadmap-item .roadmap-title {
    font-size: 1em;
  }

  .roadmap-item .roadmap-title {
    font-size: 0em;
  }

  .roadmap-left {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }

  .roadmap-right {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
}

/* --------- */
/* tokenomics */
/* --------- */
.tokenomics-section {
  padding-bottom: 7rem 0;
}

.tokenomics-content {
  background-image: url('Assets/main/bg-token.webp');
  border-radius: 2rem;
  padding: 2rem;
  cursor: pointer;
  z-index: 999;
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.1s;
  -webkit-transition-timing-function: ease;
  margin-left: 0 !important;
}

.videoToken {
  width: 20rem;
}

.token-text {
  color: white;
  font-size: 4rem;
  font-weight: 700;
}
.token-text2 {
  color: white;
  font-size: 1rem;
  font-weight: 400;
}
.token-text3 {
  color: white;
  font-size: 2rem;
  font-weight: 400;
}
.token-text3 li {
  list-style-type: none;
}

@media (max-width: 980px) {
  .videoToken {
    width: 15rem;
  }

  .token-text {
    font-size: 2rem;
  }
  .token-text2 {
    font-size: 0.7rem;
  }
  .token-text3 {
    font-size: 1rem;
    padding: 0;
  }
  .token-text3 h1 {
    font-size: 2rem;
  }
}

/* --------- */
/* Footer */
/* --------- */
.footer {
  background-color: #001412;
  opacity: 0.7;
  bottom: 0 !important;
  padding-top: 10px !important;
  padding-bottom: 8px !important ;
}
.footer-copywright {
  text-align: center !important;
}

.footer-body {
  text-align: center !important;
}

@media (max-width: 767px) {
  .footer-copywright {
    text-align: center !important;
  }

  .footer-body {
    text-align: center !important;
  }
}

.footer h3 {
  font-size: 1em;
  color: white !important;
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
}

.footer h1 {
  font-size: 0.7em;
  color: white !important;
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
}
.footer-icons {
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
  padding: 0 !important;
}

.blockquote-footer {
  color: #000000 !important;
}
